
import { defineComponent } from "vue";

export default defineComponent({
  name: "payment-methods",
  props: {
    cardClasses: String
  },
  components: {}
});
